// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;


// Grayscale
$white:                 #fff !default;
$gray-100:              #f7f7fc !default;
$gray-200:              #f3f3f9 !default;
$gray-300:              #e9e9f2 !default;
$gray-400:              #dfdfeb !default;
$gray-500:              #9e9fb4 !default;
$gray-600:              #737491 !default;
$gray-700:            	#5a5b75 !default;
$gray-800: #313030 !default;
$gray-900: #2b2b2c !default;
$black:                 #000 !default;

// Brand colors
$primary:               $white !default;
$secondary:             $gray-100 !default;
$info:		              #6a9bf4 !default;
$success:               #16c995 !default;
$warning:               #ffb15c !default;
$danger:                #f74f78 !default;
$light:                 $white !default;
$dark:                  $gray-800 !default;

// Body

$body-color:            $white;
$body-bg:               $gray-900;

$headings-color:              $white !default;

//$navbar-dark-color:                       $gray-900 !default;
//$navbar-dark-hover-color:                 $white !default;
//$navbar-dark-active-color:                $white !default;
//$navbar-dark-disabled-color:              rgba($white, .45) !default;
//$navbar-dark-toggler-border-color:        transparent !default;
//$navbar-dark-tool-color:                  $gray-900 !default;
//$navbar-dark-tool-hover-color:            $gray-900 !default;

$position-values: (
        0: 0,
        1: 1%,
        15: 15%,
        25: 25%,
        50: 50%,
        70: 70%,
        75: 75%,
        100: 100%
);